import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeLog, MessageList, UserMessage } from "models";
import { RootState } from "redux/app/store";

export interface MessageSlice {
  value: MessageList;
}
const initialState: MessageSlice = {
  value: {
    change_logs: [],
    message_status: 1,
    is_employee_set_status: undefined,
    user: {},
    user_message: [],
    mark_as_read: 0,
  },
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessages(state, action: PayloadAction<MessageList>) {
      state.value = {
        ...state.value,
        change_logs: action.payload.change_logs,
        message_status: action.payload.message_status,
        user: action.payload.user,
        is_employee_set_status: action.payload.is_employee_set_status,
        user_message: [
          ...state.value.user_message,
          ...action.payload.user_message,
        ],
        mark_as_read: action.payload.mark_as_read,
      };
    },
    addMessage(state, action: PayloadAction<UserMessage>) {
      let findId = !!state.value.user_message.find(
        (item) => item.id === action.payload.id
      );

      state.value = {
        ...state.value,
        user_message: findId
          ? state.value.user_message
          : [...state.value.user_message, action.payload],
      };
    },
    addChangeLog(state, action: PayloadAction<ChangeLog>) {
      let findId = !!state.value.change_logs.find(
        (item) => item.id === action.payload.id
      );

      state.value = {
        ...state.value,
        change_logs: findId
          ? state.value.change_logs
          : [...state.value.change_logs, action.payload],
      };
    },
    changeMessageStatus(state, action: PayloadAction<number>) {
      state.value = {
        ...state.value,
        message_status: action.payload,
      };
    },
    clearMessage(state) {
      state.value = {
        ...state.value,
        message_status: 1,
        user: {},
        user_message: [],
        mark_as_read: 0,
      };
    },
  },
});

//Actions
export const messageActions = messageSlice.actions;

//Selector

export const selectMessage = (state: RootState) => state.message.value;
export const selectMessageLog = (state: RootState) => {
  const mergedArray = [
    ...state.message.value.user_message,
    ...state.message.value.change_logs,
  ].sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
  return mergedArray;
};

//Reducer
export const messageReducer = messageSlice.reducer;
